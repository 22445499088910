import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';

@Injectable()
export class AlertUtilsService {
  showSimpleConfirmation(confirmationOptions: ConfirmationOptions) {
    this.showConfirmation('', 'Are you sure?', 'Confirm', confirmationOptions);
  }

  showConfirmation(
    title: string,
    message: string,
    confirmButtonText: string,
    confirmationOptions: ConfirmationOptions
  ) {
    const options: SweetAlertOptions = {
      title: title,
      html: message,
      cancelButtonColor: '#6e7881c4',
      confirmButtonColor: confirmationOptions.confirmButtonColor ?? '#128e67',
      confirmButtonText: confirmButtonText,
      heightAuto: false,
    };
    if (confirmationOptions.icon) {
      options.icon = confirmationOptions.icon;
    }
    if (confirmationOptions.didDestroy) {
      options.didDestroy = confirmationOptions.didDestroy;
    }
    if (Object.hasOwn(confirmationOptions, 'focusCancel')) {
      options.focusCancel = confirmationOptions.focusCancel;
    }
    if (Object.hasOwn(confirmationOptions, 'cancelButtonText')) {
      options.cancelButtonText = confirmationOptions.cancelButtonText;
    }
    if (Object.hasOwn(confirmationOptions, 'showCancelButton')) {
      options.showCancelButton = confirmationOptions.showCancelButton;
    } else {
      options.showCancelButton = true;
    }
    if (Object.hasOwn(confirmationOptions, 'imageUrl')) {
      options.imageUrl = confirmationOptions.imageUrl;
    }
    if (Object.hasOwn(confirmationOptions, 'imageWidth')) {
      options.imageWidth = confirmationOptions.imageWidth;
    }
    if (Object.hasOwn(confirmationOptions, 'imageHeight')) {
      options.imageHeight = confirmationOptions.imageHeight;
    }
    if (Object.hasOwn(confirmationOptions, 'imageAlt')) {
      options.imageAlt = confirmationOptions.imageAlt;
    }
    Swal.fire(options).then((result) => {
      if (result.isConfirmed && confirmationOptions.onConfirm) {
        confirmationOptions.onConfirm();
      } else if (
        result.isDismissed &&
        result.dismiss === Swal.DismissReason.cancel &&
        confirmationOptions.onDismiss
      ) {
        confirmationOptions.onDismiss();
      }
    });
  }
}

interface ConfirmationOptions {
  icon?: SweetAlertIcon | null;
  showCancelButton?: boolean;
  onConfirm?: (() => void) | null;
  onDismiss?: (() => void) | null;
  didDestroy?: (() => void) | null;
  confirmButtonColor?: string;
  cancelButtonText?: string;
  focusCancel?: boolean;
  imageUrl?: string;
  imageHeight?: number;
  imageWidth?: number;
  imageAlt?: string;
}
