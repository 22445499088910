import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { AnalyticsService } from './analytics.service';
import { LocalStorage } from './localstore.service';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public onLoginCompleted: Subject<object> = new Subject();
  public accessToken: any;
  public tokenKey = 'ohmPayToken';

  constructor(
    private readonly localStore: LocalStorage,
    private readonly analyticsService: AnalyticsService
  ) {}

  /**
   * Method to check if the user is loggedIn
   */
  isLoggedIn(): any {
    if (this.accessToken) {
      return true;
    }
    const existingToken = this.localStore.get(this.tokenKey);
    if (existingToken) {
      this.accessToken = existingToken.accessToken;
      return true;
    }
    return false;
  }

  getRefreshToken() {
    const existingToken = this.localStore.get(this.tokenKey);
    if (existingToken) {
      return existingToken.refreshToken;
    }
    return null;
  }

  getUserData() {
    const decodedToken = helper.decodeToken(this.accessToken);
    return decodedToken?.user;
  }

  login(token: any) {
    if (token) {
      this.accessToken = token.accessToken;
      const decodedToken = helper.decodeToken(this.accessToken);
      if (decodedToken?.user) {
        this.analyticsService.setUser(decodedToken.user.id, {
          $mobileNumber: decodedToken.user.mobileNumber,
          $isInternalUser: decodedToken.user.isInternalUser,
        });
      }
      this.localStore.store(this.tokenKey, token);
      return true;
    }
    return false;
  }

  /**
   * Method to logout of the application (API)
   */
  logout() {
    this.clearOfflineData();
    this.analyticsService.resetUser();
  }

  /**
   * Method to clear all offline data
   */
  clearOfflineData() {
    this.accessToken = null;
    this.localStore.clearAll();
  }
}
