import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-empty-page',
  templateUrl: './empty-page.component.html',
  styleUrls: ['./empty-page.component.scss'],
})
export class EmptyPageComponent {
  @Input() image!: string;
  @Input() message!: string;
  @Input() primaryButtonTitle!: string;
  @Input() isPrimaryButtonDisabled: boolean = false;
  @Input() primaryButtonTooltip: string = '';
  @Input() messageFontSize: string = '';
  @Output() onPrimaryButtonClick: EventEmitter<any> = new EventEmitter();

  _onPrimaryButtonClick() {
    this.onPrimaryButtonClick.emit();
  }
}
