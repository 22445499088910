import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './../services/auth.service';

@Injectable({
  providedIn: 'root',
})
class PermissionsService {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    snapshot: RouterStateSnapshot
  ): boolean {
    const urls = snapshot.url.split('/');
    const tokenStatus = this.authService.isLoggedIn();
    if (tokenStatus) {
      return true;
    } else {
      if (urls[1] == 'login' || urls[1].startsWith('login?')) {
        return true;
      }
      const queryParams: any = {};
      if (snapshot.url && snapshot.url != '/') {
        queryParams.redirectUrl = snapshot.url.substring(1);
      }
      this.router.navigate(['login'], {
        replaceUrl: true,
        queryParams,
      });
    }
    return false;
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(PermissionsService).canActivate(next, state);
};
