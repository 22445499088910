export enum MobileAppEvents {
  SendAuthDetails = 'SendAuthDetails',
  AuthDetailsReceived = 'AuthDetailsReceived',
  RefreshAuthDetails = 'RefreshAuthDetails',
  RefreshedAuthDetailsReceived = 'RefreshedAuthDetailsReceived',
  RefreshedAuthDetailsFailed = 'RefreshedAuthDetailsFailed',
  SessionExpired = 'SessionExpired',
  GoToHomePage = 'GoToHomePage',
  GetUpiIntentDetails = 'GetUpiIntentDetails',
  UpiIntentDetailsResponse = 'UpiIntentDetailsResponse',
  PaymentGatewayOpened = 'PaymentGatewayOpened',
  PaymentGatewayDismissed = 'PaymentGatewayDismissed',
  OpenPaymentGateway = 'OpenPaymentGateway',
  PaymentSuccess = 'PaymentSuccess',
  PaymentFailed = 'PaymentFailed',
  GoBack = 'GoBack',
  OpenUpiAutoPayDeepLinkUrl = 'OpenUpiAutoPayDeepLinkUrl',
  UpiAutoPayDeepLinkUrlClosed = 'UpiAutoPayDeepLinkUrlClosed',
  ShareViaWA = 'ShareViaWA',
  ReferralCodeSharingFailed = 'ReferralCodeSharingFailed',
}
