import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

import { Constants } from './../../configs/app.config';
import { NotifyService } from './notify.service';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  key: string;

  constructor(
    private readonly appConstants: Constants,
    private readonly notifyService: NotifyService
  ) {
    this.key = this.appConstants.EncryptKey;
  }

  /**
   * Method to encrypt data
   *
   */
  encrypt(rawData: any) {
    const encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(rawData),
      this.key,
      { mode: CryptoJS.mode.CTR }
    );
    return encrypted.toString();
  }

  /**
   * Method to decrypt data
   *
   */
  decrypt(encryptedData: any): string {
    if (!encryptedData) {
      return encryptedData;
    }
    if (!this.key) {
      this.handleDecryptionError('Encryption key does not exist');
    }
    let decryptedText = '';
    try {
      const decrypted = CryptoJS.AES.decrypt(encryptedData, this.key, {
        mode: CryptoJS.mode.CTR,
      });
      decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error: any) {
      this.handleDecryptionError(
        'Invalid Encryption Key' + (error.message ? ' : ' + error.message : '')
      );
    }
    if (!decryptedText) {
      this.handleDecryptionError('Invalid decryption');
    }
    return decryptedText;
  }

  /**
   * Method to MD5 a key
   */
  md5(key: any) {
    const hash = CryptoJS.MD5(key);
    return hash.toString();
  }

  handleDecryptionError(errorMessage: string) {
    this.notifyService.showError(errorMessage);
    throw new Error(errorMessage);
  }
}
