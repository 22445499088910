import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as mixpanel from 'mixpanel-browser';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    private readonly gaService: GoogleAnalyticsService,
    private readonly translateService: TranslateService
  ) {
    mixpanel.init(environment.mixpanelToken, {
      debug: !environment.production,
      track_pageview: false,
      persistence: 'localStorage',
    });
  }

  track(
    action: string,
    category?: string,
    label?: string,
    value?: number,
    properties?: any
  ): void {
    this.gaService.event(action, category, label, value);
    mixpanel.track(action, {
      ..._.omitBy(
        {
          category,
          label,
          value,
        },
        _.isNil
      ),
      ...properties,
    });
  }

  trackEvent(name: string, properties?: any): void {
    this.gaService.event(name);
    mixpanel.track(name, {
      ...properties,
    });
  }

  trackPageView(path: string) {
    mixpanel.default.track_pageview();
  }

  setUser(userId: string, userDetails: any) {
    userDetails['$selectedLanguage:'] = this.translateService.currentLang;
    this.gaService.set('userId', userId);
    this.gaService.set('user_properties', userDetails);
    mixpanel.register(userDetails);
    mixpanel.identify(userId);
    mixpanel.people.set(userDetails);
  }

  resetUser() {
    mixpanel.reset();
  }
}
