import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NgControl,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from '@angular/material/form-field';
import { Subject } from 'rxjs';

export class FileDto {
  constructor(public id: string, public displayName: string) {}
}

/** Custom `MatFormFieldControl` for currency inputs to display with commas. */
@Component({
  selector: 'number-input',
  templateUrl: 'number-input.component.html',
  providers: [{ provide: MatFormFieldControl, useExisting: NumberInput }],
  host: {
    '[id]': 'id',
  },
})
export class NumberInput
  implements ControlValueAccessor, MatFormFieldControl<number>, OnDestroy
{
  private static nextId = 0;

  amount = '';
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'number-input';
  id = `number-input-${NumberInput.nextId++}`;

  formControl = new FormControl();
  @Input() control!: AbstractControl | null;

  onChangeRef = (_: any) => {};
  onTouched = () => {};

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  get empty() {
    return !this.amount;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy!: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder!: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formControl.disable() : this.formControl.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): number | null {
    const amount = this.formControl.value;
    return amount ? parseFloat(amount) : null;
  }

  set value(value: number | null) {
    this.amount = value === null ? '' : value + '';
    this.formControl.setValue(this.amount);
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.formControl.invalid && this.touched;
  }

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _focusMonitor: FocusMonitor,
    private readonly __elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.writeValue = this.writeValue.bind(this);
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this.__elementRef);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: any) {
    value = value.replace(/[^\d.-]/g, '');
    this.writeValue(value ? parseFloat(value) : null);
  }

  onChangeValue(value: number | null) {
    this.onChangeRef(value);
    this.onChange.emit(value);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this.__elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  autoFocusNext(
    control: AbstractControl,
    nextElement?: HTMLInputElement
  ): void {
    if (!control.errors && nextElement) {
      this._focusMonitor.focusVia(nextElement, 'program');
    }
  }

  autoFocusPrev(control: AbstractControl, prevElement: HTMLInputElement): void {
    if (control.value.length < 1) {
      this._focusMonitor.focusVia(prevElement, 'program');
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement =
      this.__elementRef.nativeElement.querySelector('.number-input')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {}

  writeValue(value: number | null): void {
    this.value = value;
    this.onChangeValue(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChangeRef = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(control: AbstractControl, nextElement?: HTMLInputElement): void {
    this.autoFocusNext(control, nextElement);
    this.onChangeValue(this.value);
  }
}
