import { Router, UrlCreationOptions } from '@angular/router';
import moment from 'moment';
import { MathUtils } from './math-utils.service';

export class UtilsService {
  static navigate(
    $event: MouseEvent,
    router: Router,
    path: any[],
    extras?: UrlCreationOptions
  ): void {
    if ($event.ctrlKey || $event.metaKey) {
      const url = router.serializeUrl(router.createUrlTree(path, extras));
      window.open(url, '_blank');
    } else {
      router.navigate(path, extras);
    }
  }

  static add(operand1: number, operand2: number): number {
    return (operand1 * 100 + operand2 * 100) / 100;
  }

  static getNoOfDaysInBetween(endDate: Date, startDate: Date): number {
    const start = moment(startDate).startOf('day');
    const end = moment(endDate).endOf('day');
    return end.diff(start, 'days');
  }

  static getShortAmount(amount: number): string {
    const absAmount = Math.abs(amount);
    if (absAmount >= 10000000) {
      return `${MathUtils.div(amount, 10000000)} CR`;
    } else if (absAmount >= 100000) {
      return `${MathUtils.div(amount, 100000)} L`;
    } else if (absAmount >= 1000) {
      return `${MathUtils.div(amount, 1000)} K`;
    }
    return Number.isNaN(parseFloat(amount + ''))
      ? '-'
      : MathUtils.trimToTwoDecimals(amount) + '';
  }

  static getFormattedAmount(amount: number): string {
    return amount.toLocaleString('en-In', {
      maximumFractionDigits: 0,
      style: 'currency',
      currency: 'INR',
    });
  }

  static isPwa() {
    return (
      window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator as any)?.standalone
    );
  }
}

export function addItemToMappingArray<T>(
  key: string,
  item: T,
  mapping: { [key: string]: T[] }
) {
  if (mapping[key]) {
    mapping[key].push(item);
  } else {
    mapping[key] = [item];
  }
}

export function parseYouTubeVideoId(url: string): string | undefined {
  const regExp =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const match = RegExp(regExp).exec(url);
  if (match?.[1]) {
    return match[1];
  }
  return undefined;
}

export function getRNWebView() {
  return (window as any).ReactNativeWebView;
}

export function isMobileApp() {
  return !!getRNWebView();
}

export function postMessageToMobileApp(message: any) {
  return getRNWebView()?.postMessage(message);
}
