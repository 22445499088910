import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom, from, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Constants, ToastMessages, ToastTypes } from '../../configs/app.config';
import { MOBILE_APP_VERSION_CODE_KEY } from '../../shared/constants';
import { postMessageToMobileApp } from '../../shared/utils/utils.service';
import { MobileAppEvents } from '../enums/mobile-app-events.enum';
import { PreloaderService } from '../preloader/preloader.service';
import { AuthService } from '../services/auth.service';
import { CryptoService } from '../services/crypto.service';
import { LocalStorage } from '../services/localstore.service';
import { LoginService } from '../services/login.service';
import { NotifyService } from '../services/notify.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  private refreshTokenPromise: Promise<string | null> | null = null;

  constructor(
    private readonly appConstants: Constants,
    private readonly preloader: PreloaderService,
    private readonly notify: NotifyService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly loginService: LoginService,
    private readonly cryptoService: CryptoService,
    private readonly localStorage: LocalStorage
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const config = JSON.parse(req.params.get('_config') ?? '{}');
    const updatedRequest = this.setRequestHeaders(req, config);
    return next.handle(updatedRequest).pipe(
      catchError((error) => {
        if (error.status === 401) {
          if (
            req.url.includes('refresh-token') ||
            !this.authService.getRefreshToken()
          ) {
            return throwError(() => error);
          }
          if (!this.refreshTokenPromise) {
            this.refreshTokenPromise = this.refreshToken();
          }
          return from(this.refreshTokenPromise).pipe(
            switchMap((newToken) => {
              this.refreshTokenPromise = null;
              if (!this.authService.login(newToken)) {
                return throwError(() => error);
              }
              return this.intercept(req.clone(), next);
            }),
            catchError((refreshError) => {
              this.refreshTokenPromise = null;
              if (refreshError.message != 'NetworkError') {
                this.logout();
              }
              return throwError(() => refreshError);
            })
          );
        }
        return throwError(() => error);
      }),
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            if (config.noLoader !== 'true') {
              this.preloader.stop();
            } else {
              return event;
            }
          }
          return true;
        },
        error: (error) => {
          if (config.noLoader !== 'true') {
            this.preloader.stop();
          }
          if (error.status === 401) {
            return;
          }
          // http response status code
          if (error instanceof HttpErrorResponse) {
            this.onSubscribeError(error, config);
          }
          return throwError(() => error);
        },
      })
    );
  }

  private async refreshToken(): Promise<string | null> {
    return new Promise((resolve, reject) => {
      if (!this.localStorage.get(MOBILE_APP_VERSION_CODE_KEY)) {
        resolve(
          firstValueFrom(
            this.loginService.refreshToken({
              refreshToken: this.authService.getRefreshToken(),
            })
          )
        );
        return;
      }
      const listener = (event: MessageEvent) => {
        try {
          let data;
          if (event.data.type) {
            data = event.data;
          } else if (typeof event.data == 'string') {
            data = JSON.parse(event.data);
          } else {
            return;
          }
          switch (data.type) {
            case MobileAppEvents.RefreshedAuthDetailsReceived:
              window.removeEventListener('message', listener);
              resolve(JSON.parse(this.cryptoService.decrypt(data.tokens)));
              break;
            case MobileAppEvents.RefreshedAuthDetailsFailed:
              if (data.isNetworkError) {
                reject(new Error('NetworkError'));
                break;
              }
              resolve(null);
              break;
          }
        } catch (error) {
          console.error('Error parsing message:', error);
          resolve(null);
        }
      };

      window.addEventListener('message', listener);

      postMessageToMobileApp(MobileAppEvents.RefreshAuthDetails);

      // to prevent infinite waiting
      setTimeout(() => {
        window.removeEventListener('message', listener);
        resolve(null);
      }, 10000);
    });
  }

  setRequestHeaders(req: HttpRequest<any>, config: any) {
    this.requestInterceptor(config.noLoader);
    if (req.url.startsWith('assets') || req.url.includes('/assets')) {
      return req;
    } else if (req.url.includes('http://') || req.url.includes('https://')) {
      return req;
    } else {
      req = req.clone({ url: this.getFullUrl(req.url) });
      req = req.clone({ params: req.params.delete('_config') });
      if (config.bypassContentType !== 'true') {
        req = req.clone({
          headers: req.headers.set('Content-Type', 'application/json'),
        });
      }
      req = req.clone({
        headers: req.headers.set('Accept', 'application/json'),
      });
      if (this.authService.accessToken) {
        req = req.clone({
          headers: req.headers.set(
            'Authorization',
            `Bearer ${this.authService.accessToken}`
          ),
        });
      }
      return req;
    }
  }

  private onSubscribeError(error: any, config: any): void {
    if (error.status === 0 && !error.ok) {
      if (!navigator.onLine) {
        this.notify.show(ToastTypes.Error, ToastMessages[0]);
      } else if (config.noNotifyError !== 'true') {
        this.notify.show(ToastTypes.Error, ToastMessages[4]);
      }
    } else if (config.noNotifyError !== 'true') {
      if (error.error?.message) {
        let message = error.error.message;
        if (message instanceof Object) {
          message = JSON.stringify(message);
        }
        this.notify.show(ToastTypes.Error, message);
      } else {
        this.notify.show(ToastTypes.Error, ToastMessages[1]);
      }
    }
  }

  private logout(notifySessionExpired = true) {
    postMessageToMobileApp(MobileAppEvents.SessionExpired);
    this.authService.logout();
    setTimeout(() => {
      const queryParams: any = {};
      if (notifySessionExpired) {
        this.notify.showSessionMessage(ToastMessages[2]);
        queryParams.redirectUrl = this.router.url.substring(1);
      }
      this.router.navigate(['login'], {
        queryParams,
      });
    }, 500);
  }

  private readonly removeSlashes = (url: string): string => {
    // tslint:disable-next-line:curly
    if (!url) return url;
    if (url.startsWith('/')) {
      url = url.slice(1, url.length);
    }
    if (url.endsWith('/')) {
      url = url.slice(0, url.length - 1);
    }
    return url;
  };

  /**
   * Build API url.
   */
  private getFullUrl(url: string): string {
    // return full URL to API here
    const qUrl = url;
    const apiBasePath = this.appConstants.APIBasePath;
    return `${this.removeSlashes(apiBasePath)}/${this.removeSlashes(qUrl)}`;
  }

  private requestInterceptor(noLoader: any): void {
    if (noLoader !== 'true') {
      this.preloader.start();
    }
  }
}
